const binding = { modules: {}, dataActions: {} };

    (binding.modules['msdyn365-cs-chat-connector'] = {
        c: () => require('@msdyn365-commerce-modules/chat/dist/lib/modules/msdyn365-cs-chat-connector/msdyn365-cs-chat-connector'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'msdyn365-cs-chat-connector',
        p: 'chat',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/chat/dist/lib/modules/msdyn365-cs-chat-connector'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['@msdyn365-commerce-modules|chat|modules|msdyn365-cs-chat-connector|msdyn365-cs-chat-connector'] = {
                c: () => require('@msdyn365-commerce-modules/chat/dist/lib/modules/msdyn365-cs-chat-connector/msdyn365-cs-chat-connector.view.js'),
                cn: '@msdyn365-commerce-modules-chat-msdyn365-cs-chat-connector'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };